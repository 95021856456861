<template>
  <div class="home">
	  
	    <van-nav-bar title="拨号" left-arrow id="reset" left-text="返回" @click-left="onClickLeft" 
right-text="清除" 
@click-right="onClickRight"/>
<!--      <div class="top">

      </div> -->
    <div class="header">
      <div class="show">
          {{value}}
      </div>
      <img src="../static/CRM/delete-1.png" alt="" width="35" height="21" @click="del">
    </div>
    <div class="num">
      <div class="son" v-for="(item,index) in numList " :key="index" @click="end(item.num)">
        <div class="s1">
          {{item.num}}
        </div>
        <div class="s2">
          {{item.eng}}
        </div>
      </div>
    </div>
    <div class="tel" @click=bohao()>
        <img src="../static/CRM/tel.png" alt="" width="61" height="61" >
    </div>
  </div>
</template>

<script>
	import { Message } from 'iview'
export default {
  data() {
    return {
        value:"",
		id:'',
      numList: [
        {
          num: 1,
          eng: "",
        },
        {
          num: 2,
          eng: "ABC",
        },
        {
          num: 3,
          eng: "DEF",
        },
        {
          num: 4,
          eng: "GHI",
        },
        {
          num: 5,
          eng: "JKL",
        },
        {
          num: 6,
          eng: "MNO",
        },
        {
          num: 7,
          eng: "PQRS",
        },
        {
          num: 8,
          eng: "TUV",
        },

        {
          num: 9,
          eng: "WXYZ",
        },
        {
          num: "*",
          eng: "",
        },
        {
          num: 0,
          eng: "",
        },
        {
          num: "#",
          eng: "",
        },
      ],
    };
  },created () {
 			// console.log(this.$route.query.id);
    // this.getbusiness()
    this.getData()
  },
  methods: {
	  // this.$route.query.id
	  getData()
	  {
		if(this.$route.query.id)
		{
			this.value = this.$route.query.id;
		}
	  },
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
    onClickRight() {
    this.value = "";
    },
	  bohao(){
		  
		  	Message.info('开始呼叫');
		  if(this.value){
			 
			 // console.log(this.value);
			 	let param = new URLSearchParams();
			 	
			 	param.append("id", this.value);
			 	 param.append("token", this.$store.getters. getDemoValue);
			 	
			 	this.axios.post('/vue.php?m=index&a=index1',param).then(res => {
			 		if (res.data.status === -1) {
			 
			 			setTimeout(()=>{
			 				this.$router.push({
			 				  path:"/"
			 				})
			 			},1000)
			 		}
			 		if (res.data.status === 1) {
			 			Message.info('呼叫中。。。。');
						
			 		
			 		}
					
					console.log(res.data.status);
			 		if (res.data.status === -5) {
			 			Message.info('被叫号码限制');
						
			 		
			 		}
			 		if (res.data.status === -3) {
			 			Message.info('没有设置计费金额');
			 		
			 		}
					
					if (res.data.status === -4) {
						Message.info('您预付费不足请充值');
					
					}
			 		console.log(res);
			 	}).catch(function (error){
			 		console.log(error);
			 	}); 
		  }else{
			  Message.info('请输入手机号');
		  }
	  },
      end(e){
          this.value  += e
      },
      del(){
          let str = this.value
        //   this.value =this.value(0,this.value.length-1)
        this.value = str.slice(0,str.length-1)
      }
  },
};
</script><style lang="scss" scoped>
.top {
  height: 50px;
  width: 100%;
  background: #2385ce;
}
.tel {
  position: fixed;
  bottom: 15%;
  width: 62px;
  height: 62px;
  left: 50%;
  transform: translateX(-50%);
}
.num {
  width: 80%;
  margin: 0 auto;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .son {
    display: flex;
    width: 30%;
    height: 77px;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    .s1 {
      color: #212121;
      font-size: 37px;
    }
    .s2 {
      height: 11px;
      font-size: 14px;
      width: 100%;
      text-align: center;
      color: #a6a6a6;
    }
  }
}
.home {
  height: 100vh;
  background: #f4f4f4;
  overflow: hidden;
}
.header {
  background: #ffffff;
  margin: 15px auto;
  padding: 10px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  width: 347px;
  height: 79px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(28, 28, 28, 0.13);
}
.show {
  font-size: 31px;
  font-weight: normal;
  color: #000000;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>